class RequestTracker {
    constructor() {
        this.callbacks = [];
    }
    onStart(startCallback) {
        this.callbacks.push(startCallback);
    }
    start(context) {
        const results = [];
        for (const startCallback of this.callbacks) {
            const result = startCallback(context);
            if (result)
                results.push(result);
        }
        return {
            onRequestEnd: (endContext) => {
                for (const result of results) {
                    if (result && result.onRequestEnd) {
                        result.onRequestEnd(endContext);
                    }
                }
            },
            extraRequestHeaders: results.map((result) => {
                if (result && result.extraRequestHeaders) {
                    return result.extraRequestHeaders;
                }
                return undefined;
            }).filter(isDefined)
        };
    }
}
function isDefined(argument) {
    return argument !== undefined;
}

export { RequestTracker };
